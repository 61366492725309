import { recipeDao } from '../../../api/recipe/dao/recipe.dao';
import { RecipeRepository } from '../../../api/recipe/repository/recipe.repository';
import {
  TypesActionsRecipe,
  LIST_RECIPE,
  SHOW_RECIPE,
  UPDATE_RECIPE,
  DELETE_RECIPE,
  GET_RECIPES,
  RESET_RECIPES_STATE,
} from './RecipeTypes';
import { ResponseRecipe, Recipe } from '../../../types/recipe/Recipe';

function listRecipe(payload: ResponseRecipe): TypesActionsRecipe {
  return {
    type: LIST_RECIPE,
    payload,
  };
}

function showRecipe(payload: Recipe): TypesActionsRecipe {
  return {
    type: SHOW_RECIPE,
    payload,
  };
}

function updateRecipe(payload: Recipe): TypesActionsRecipe {
  return {
    type: UPDATE_RECIPE,
    payload,
  };
}

function deleteRecipe(payload: string): TypesActionsRecipe {
  return {
    type: DELETE_RECIPE,
    payload,
  };
}

function getRecipes(payload: ResponseRecipe): TypesActionsRecipe {
  return {
    type: GET_RECIPES,
    payload,
  };
}

export function resetRecipesState(): TypesActionsRecipe {
  return {
    type: RESET_RECIPES_STATE,
  };
}

export function listRecipeAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await recipeDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listRecipe(response.data));
  };
}

export function showRecipeAsync(
  id: string,
  accountId: string,
  callback: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await recipeDao.show(id, accountId);
      dispatch(showRecipe(response.data));
    } catch (request) {
      alert(JSON.parse(request.response).message);
      callback();
    }
  };
}

export function createRecipeAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await RecipeRepository.create(form);
      callbackSuccess();
      dispatch(showRecipe(response.data));
    } catch (request) {
      callbackError();
    }
  };
}

export function updateRecipeAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await RecipeRepository.update(form);

      dispatch(updateRecipe(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export function deleteRecipeAsync(
  form: {
    id: string;
    accountId: string;
  },
  type: string,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      await RecipeRepository.delete(form);
      const response = await recipeDao.list(
        form.accountId,
        null,
        null,
        null,
        `type=${type}`
      );
      dispatch(deleteRecipe(form.id));
      dispatch(listRecipe(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export function getRecipesAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await recipeDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(getRecipes(response.data));
  };
}
