import * as Yup from 'yup';
import { FormikProps } from 'formik';
import { Account } from '../accounts/Accounts';
import { Order, ResponseOrdersProps } from './Orders';
import { TableBasic } from '../tables/Tables';
import { Food } from '../foods/Food';
import { PaymentTypes, TypeDocumentIdSpanish } from '../..//utils/enums';
import { UserAuth } from '../users/User';
import { UserInitialValues } from '../users/interfaces';
import { PlanInitialValues } from '../accounts/interfaces';
import { FoodCategory } from '../foodCategories/FoodCategory';
import { FoodComplement } from '../foodComplements/FoodComplement';
import { OrderTable } from '../checkIn/interface';

export interface ElectronicInvoice {
  documentType: string;
  documentId: string;
  name: string;
  email: string;
  phone?: string;
  status?: string;
}

export const electronicInvoiceInitialValues: ElectronicInvoice = {
  documentType: TypeDocumentIdSpanish.CI,
  documentId: '',
  name: '',
  email: '',
  phone: '',
};

export interface TableOrder extends Omit<TableBasic, 'url'> {}
export interface FoodOrder extends Omit<Food, 'quantity' | 'total'> {}

export interface OrdersFormValues {
  id?: string;
  _id?: string;
  total: number;
  food: Array<string>;
  foods: Array<FoodOrder>;
  tableId: TableOrder;
  account: Account;
  paymentId: string;
  paymentType: string;
  check?: boolean;
  electronicInvoice?: ElectronicInvoice;
}

export interface FormProps {
  formikInstance: FormikProps<OrdersFormValues>;
  selectedAccount: string;
  setValidate: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface FormBillProps {
  formikInstance: FormikProps<OrdersFormValues>;
}

export interface TopInputsProps {
  formikInstance: FormikProps<OrdersFormValues>;
  tables: TableBasic[];
}

export interface FoodListProps {
  formikInstance: FormikProps<OrdersFormValues>;
  foods: Food[];
  foodsFiltered: Food[];
  setFoodsFiltered: React.Dispatch<React.SetStateAction<Food[]>>;
  foodsSearched: Food[];
  setFoodsSearched: React.Dispatch<React.SetStateAction<Food[]>>;
  foodCategories: FoodCategory[];
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  setValidate: React.Dispatch<React.SetStateAction<boolean>>;
  setComplements: React.Dispatch<React.SetStateAction<FoodComplement[]>>;
  setFoodTemp: React.Dispatch<React.SetStateAction<FoodTemp>>;
  foodTemp: FoodTemp;
  setComplementsPrice: React.Dispatch<React.SetStateAction<number>>;
}

export interface SelectedFoodProps {
  foodTemp: FoodTemp;
  setFoodTemp: React.Dispatch<React.SetStateAction<FoodTemp>>;
  complementsPrice: number;
  quantity: number;
  complements: FoodComplement[];
  formikInstance: FormikProps<OrdersFormValues>;
  setValidate: React.Dispatch<React.SetStateAction<boolean>>;
  clearFoodTemp: () => void;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  setComplementsPrice: React.Dispatch<React.SetStateAction<number>>;
  setFoodOrder: React.Dispatch<React.SetStateAction<FoodTemp[]>>;
}

export interface OrderResumeProps {
  formikInstance: FormikProps<OrdersFormValues>;
  foodOrder: FoodTemp[];
  setFoodOrder: React.Dispatch<React.SetStateAction<FoodTemp[]>>;
}

export interface ManagementAccountsDetailProps {
  getAccount: (id: string, callback: Function) => void;
  user: UserAuth;
  account: Account;
}

export interface ManagementOrdersProps {
  user: UserAuth;
  account: Account;
  orders: ResponseOrdersProps;
  getOrders: (
    page: number,
    limit: number,
    accountId: string,
    search: string | null
  ) => Promise<void>;
  updateOrder: (order: Order, callback: Function) => Promise<void>;
  getAccount: (accountId: string, callback: Function) => Promise<void>;
  completeElectronicInvoice: (id: string, callback: Function) => Promise<void>;
}

export interface ManagementOrdersCreateProps {
  user: UserAuth;
  account: Account;
  createOrder: (
    form: object,
    callback: Function,
    setValidate: (value: boolean) => void
  ) => Promise<void>;
  getAccount: (accountId: string, callback: Function) => Promise<void>;
}

export interface ManagementOrdersUpdateProps {
  account: Account;
  order?: Order;
  updateTable: (form: object, callback: Function) => void;
  getTable: (id: string, callback: Function) => void;
  getAccount: (accountId: string, callback: Function) => Promise<void>;
}

export interface OrderDetailProps {
  getOrder: (id: string, callback: Function) => void;
  order: Order;
  user: UserAuth;
}

export interface ModalOrdersProps {
  modalVisible: boolean;
  setModalVisible: (value: React.SetStateAction<boolean>) => void;
  statusChange: {
    status: string;
    paymentStatus: string;
    tableId: TableBasic;
  };
  setStatusChange: (
    value: React.SetStateAction<{
      status: string;
      paymentStatus: string;
      tableId: TableBasic;
    }>
  ) => void;
  orderSelected: Order;
  updateOrder: (order: Order, callback: Function) => Promise<void>;
  tables: TableBasic[];
}

export interface FoodTemp {
  _id: string;
  image: string;
  name: string;
  price: number;
  complements: FoodComplement[];
  complementsOptions: FoodComplement[];
  total: number;
  quantity: number;
  discountPercent: number;
  specialInstructions: string;
  timeStampId: number;
  subTotal: number;
  unitPrice: number;
  otherFee: number;
  vatFee: number;
  discountFee: number;
  otherPercentage: number;
  servicePercentage: number;
  vatPercentage: number;
  serviceFee: number;
  ivaPercentage?: number;
  cabys?: string;
}

export const foodTempInitialValues: FoodTemp = {
  _id: '',
  image: '',
  name: '',
  price: 0,
  complements: [],
  complementsOptions: [],
  total: 0,
  subTotal: 0,
  quantity: 1,
  discountPercent: 0,
  specialInstructions: '',
  timeStampId: null,
  discountFee: 0,
  vatFee: 0,
  otherFee: 0,
  unitPrice: 0,
  otherPercentage: 0,
  servicePercentage: 0,
  vatPercentage: 0,
  serviceFee: 0,
  ivaPercentage: 0,
  cabys: '',
};

export const initialValues: OrdersFormValues = {
  id: '',
  total: 0,
  food: [],
  foods: [],
  tableId: {
    _id: '',
    id: '',
    name: '',
    isEnable: false,
    account: '',
    createdAt: '',
    deletedAt: '',
    __v: 0,
  },
  account: {
    _id: '',
    name: '',
    documentId: '',
    country: '',
    state: '',
    city: '',
    address: '',
    zip: '',
    phone: '',
    email: '',
    representative: '',
    description: '',
    plan: {
      _id: '',
      name: '',
      description: '',
      active: false,
      price: 0,
      rules: {},
      setupFee: 0,
    },
    isEnable: false,
    lat: '',
    lon: '',
    status: '',
    image: '',
    banner: '',
    createdAt: '',
    feeServicesQuota: [],
  },
  check: false,
  paymentId: '629111cab086bc807088a6da',
  paymentType: PaymentTypes.OFFLINE,
};

export const initialOrderValues: Order = {
  _id: '',
  id: '',
  account: {
    _id: '',
    id: '',
    name: '',
    documentId: '',
    country: '',
    state: '',
    city: '',
    address: '',
    zip: '',
    phone: '',
    email: '',
    representative: '',
    description: '',
    plan: PlanInitialValues,
    isEnable: false,
    lat: '',
    lon: '',
    status: '',
    image: '',
    banner: '',
    createdAt: '',
    feeServicesQuota: [],
  },
  code: '',
  description: '',
  total: 0,
  status: '',
  tracking: [],
  createdAt: '',
  createdById: UserInitialValues,
  foods: [],
  food: [],
  paymentId: '',
  paymentStatus: '',
  tableId: {
    _id: '',
    id: '',
    name: '',
    isEnable: false,
    account: '',
    createdAt: '',
    deletedAt: '',
    __v: 0,
    url: '',
  },
  electronicInvoice: {
    documentId: '',
    documentType: '',
    email: '',
    name: '',
    phone: '',
  },
  orderType: '',
  cashRegisterId: '',
  cashRegisterMovementOpenId: '',
  otherPercentage: 0,
  servicePercentage: 0,
  vatPercentage: 0,
  otherFee: 0,
  vatFee: 0,
  discountFee: 0,
  subTotal: 0,
  serviceFee: 0,
  generalDiscountPrice: 0,
  generalDiscountPriceToPay: 0,
  otherPercentageToPay: 0,
  servicePercentageToPay: 0,
  vatPercentageToPay: 0,
  otherFeeToPay: 0,
  vatFeeToPay: 0,
  discountFeeToPay: 0,
  subTotalToPay: 0,
  serviceFeeToPay: 0,
  totalToPay: 0,
};

export const initialOrderTable: OrderTable = {
  orders: [],
  table: {
    _id: '',
    id: '',
    name: '',
    isEnable: false,
    account: '',
    createdAt: '',
    deletedAt: '',
    __v: 0,
    url: '',
  },
};
