import { SalesDao } from '../../../api/sales/dao/sales.dao';
import { SalesRepository } from '../../../api/sales/repository/sales.repository';

import {
  TypesActionsSale,
  LIST_SALE,
  SHOW_SALE,
  UPDATE_SALE,
  DELETE_SALE,
  // GET_SALES,
  RESET_SALES_STATE,
} from './SalesTypes';
import { ResponseSales, Sale } from '../../../types/sales/Sales';

function listSale(payload: ResponseSales): TypesActionsSale {
  return {
    type: LIST_SALE,
    payload,
  };
}

function showSale(payload: Sale): TypesActionsSale {
  return {
    type: SHOW_SALE,
    payload,
  };
}

function updateSale(payload: Sale): TypesActionsSale {
  return {
    type: UPDATE_SALE,
    payload,
  };
}

function deleteSale(payload: string): TypesActionsSale {
  return {
    type: DELETE_SALE,
    payload,
  };
}

// function getSales(payload: ResponseSales): TypesActionsSale {
//   return {
//     type: GET_SALES,
//     payload,
//   };
// }

export function resetSalesState(): TypesActionsSale {
  return {
    type: RESET_SALES_STATE,
  };
}

export function listSaleAsync(
  numberPage: number,
  limit: number,
  accountId: string,
  search: string | null,
  filter: string | null
) {
  return async function (dispacth: Function) {
    const response = await SalesDao.list(
      accountId,
      numberPage,
      limit,
      search,
      filter
    );
    dispacth(listSale(response.data));
  };
}

export function showSaleAsync(
  id: string,
  accountId: string,
  callback: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await SalesDao.show(id, accountId);
      dispatch(showSale(response.data));
    } catch (request) {
      alert(JSON.parse(request.response).message);
      callback();
    }
  };
}

export function createSaleAsync(
  form: object,
  callbackSuccess: (sale: Sale) => void,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await SalesRepository.create(form);
      callbackSuccess(response.data);
      dispatch(showSale(response.data));
    } catch (request) {
      callbackError();
    }
  };
}

export function updateSaleAsync(
  form: object,
  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      const response = await SalesRepository.update(form);

      dispatch(updateSale(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

export function deleteSaleAsync(
  form: {
    id: string;
    accountId: string;
  },

  callbackSuccess: Function,
  callbackError: Function
) {
  return async function (dispatch: Function) {
    try {
      await SalesRepository.delete(form);
      const response = await SalesDao.list(
        form.accountId,
        null,
        null,
        null,
        null
      );
      dispatch(deleteSale(form.id));
      dispatch(listSale(response.data));
      callbackSuccess();
    } catch (request) {
      callbackError();
    }
  };
}

// export function getSalesAsync(
//   numberPage: number,
//   limit: number,
//   accountId: string,
//   search: string | null,
//   filter: string | null
// ) {
//   return async function (dispacth: Function) {
//     const response = await SalesDao.list(
//       accountId,
//       numberPage,
//       limit,
//       search,
//       filter
//     );
//     dispacth(getSales(response.data));
//   };
// }
