import * as Yup from 'yup';

import { UserAuth } from '../users/User';
import { ResponseOrdersProps } from '../orders/Orders';
import { TableBasic } from '../tables/Tables';
import { AccountSimplifiedPlanValues } from '../accounts/interfaces';
import {
  GeneralSale,
  ResponseGeneralSale,
  GeneralSaleProduct,
} from './GeneralSales';

export interface OrdersGeneralSalesProps {
  orders: ResponseOrdersProps;
  listOrders: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  tables: TableBasic[];
  getTables: (accountId: string) => Promise<void>;
  user: UserAuth;
}

export interface GeneralSalesScreenProps {
  user: UserAuth;
  generalSales: ResponseGeneralSale;
  listGeneralSale: (
    numberPage: number,
    limit: number,
    accountId: string,
    search: string,
    filter: string
  ) => Promise<void>;
  deleteGeneralSale: (
    form: {
      id: string;
      accountId: string;
    },
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
}

export interface CreateGeneralSalesProps {
  user: UserAuth;
  createGeneralSale: (
    form: object,
    callbackSuccess: (generalSale: GeneralSale) => void,
    callbackError: Function
  ) => Promise<void>;
}

export interface EditGeneralSalesProps {
  user: UserAuth;
  updateGeneralSale: (
    form: object,
    callbackSuccess: Function,
    callbackError: Function
  ) => Promise<void>;
  getGeneralSale: (
    id: string,
    accountId: string,
    callback: Function
  ) => Promise<void>;
  generalSale: GeneralSale;
}

export interface ProductFormValues extends GeneralSaleProduct {}

export interface GeneralSalesFormValues {
  accountId?: string;
  amount: number;
  observations: string;
  products: ProductFormValues[];
  id?: string;
  _id?: string;
  customerName: string;
  date: string;
  reference?: string;
}

export const initialValues: GeneralSalesFormValues = {
  accountId: '',
  amount: 0,
  observations: '',
  products: [],
  customerName: '',
  date: '',
  reference: '',
};

export const validationSchema: Yup.SchemaOf<GeneralSalesFormValues> =
  Yup.object({
    accountId: Yup.string(),
    amount: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    observations: Yup.string(),
    products: Yup.array(
      Yup.object().shape({
        name: Yup.string(),
        qty: Yup.number().typeError('Debe ser una cantidad válida'),
        unitPrice: Yup.number().typeError('Debe ser una cantidad válida'),
        total: Yup.number().typeError('Debe ser una cantidad válida'),
        notes: Yup.string(),
      })
    ).min(1, 'Se requiere algregar al menos un producto'),
    id: Yup.string(),
    customerName: Yup.string().required('Este campo es requerido'),
    date: Yup.string().required('Este campo es requerido'),
    reference: Yup.string(),
    _id: Yup.string(),
  });

export const initialValuesProduct: ProductFormValues = {
  name: '',
  qty: 1,
  unitPrice: 0,
  total: 0,
  notes: '',
};

export const validationSchemaProduct: Yup.SchemaOf<ProductFormValues> =
  Yup.object({
    name: Yup.string().required('Este campo es requerido'),
    qty: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    unitPrice: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    total: Yup.number()
      .typeError('Debe ser un número, separado por puntos. Ej: 0.0')
      .test('Validacion de Cantidad', 'Debe ser mayor a 0', (value) =>
        value <= 0 ? false : true
      )
      .required('Este campo es requerido'),
    notes: Yup.string(),
  });

export const GeneralSaleValues: GeneralSale = {
  _id: '',
  accountId: AccountSimplifiedPlanValues,
  customerName: '',
  amount: 0,
  observations: '',
  date: '',
  reference: '',
  products: [],
  createdAt: '',
  deletedAt: '',
  __v: 0,
};
